import { Button, Card, Space, Spin, Tag, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from '../../../../types';
import DriverStatus from '../../drivers/DriverStatus';
import AssignDriverModal from './AssignDriverModal';
import EditRouteDetailModal from './EditRouteDetailModal';
import RouteLogModal from './RouteLogModal';
import { SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import DeleteRouteBtn from './routeMapSection/DeleteRouteBtn';

const { Text, Title } = Typography;

type HeaderSectionProps = {
  routeInfo: { [key: string]: any };
  refetch: Function;
  loading: boolean;
};

const HeaderSection = ({ routeInfo, refetch, loading }: HeaderSectionProps) => {
  const { t } = useTranslation();
  const [showDriverModal, setShowDriverModal] = useState(false);
  const [showRouteLogModal, setShowRouteLogModal] = useState(false);

  return (
    <div>
      <Space style={{ width: '100%' }}>
        <Title level={3}>{`${t('route.routeDetail.title')} [${
          routeInfo.name
        }]`}</Title>
        <Tooltip title={t('common.refresh')}>
          <Button
            type="primary"
            shape="circle"
            icon={<SyncOutlined />}
            onClick={() => refetch()}
          />
        </Tooltip>
      </Space>
      <Spin spinning={loading}>
        <div style={styles.root}>
          <Space direction="vertical" style={styles.basicInfo}>
            <Text>
              <span style={{ fontWeight: 'bold' }}>
                {t('route.routeDetail.header.id') + ': '}
              </span>
              {routeInfo.id}
            </Text>
            <Text>
              <span style={{ fontWeight: 'bold' }}>
                {t('route.routeDetail.header.name') + ': '}
              </span>
              {routeInfo.name}
            </Text>
            <Text>
              <span style={{ fontWeight: 'bold' }}>
                {t('route.routeDetail.header.shortName') + ': '}
              </span>
              {routeInfo.short_name}
            </Text>
            <Text>
              <span style={{ fontWeight: 'bold' }}>
                {t('route.routeDetail.header.date') + ': '}
              </span>
              {routeInfo.shipping_date}
            </Text>
            <Text>
              <span style={{ fontWeight: 'bold' }}>
                {t('route.routeDetail.header.startPoint') + ': '}
              </span>
              {routeInfo.startAddress.label
                ? `${routeInfo.startAddress.label} - ${routeInfo.startAddress.name}`
                : routeInfo.startAddress.name}
            </Text>
            <Text>
              <span style={{ fontWeight: 'bold' }}>
                {t('route.routeDetail.header.endPoint') + ': '}
              </span>
              {routeInfo.endAddress.label
                ? `${routeInfo.endAddress.label} - ${routeInfo.endAddress.name}`
                : routeInfo.endAddress.name}
            </Text>
            <Text>
              <span style={{ fontWeight: 'bold' }}>
                {t('route.routeDetail.header.startAt') + ': '}
              </span>
              {routeInfo.start_at
                ? dayjs(routeInfo.start_at).format('YYYY/MM/DD HH:mm')
                : 'N/A'}
            </Text>
            <Text>
              <span style={{ fontWeight: 'bold' }}>
                {t('route.routeDetail.header.orderAmount') + ': '}
              </span>
              {routeInfo.orderCount}
            </Text>
            <Text>
              <span style={{ fontWeight: 'bold' }}>
                {t('route.routeDetail.header.totalDistance') + ': '}
              </span>
              {routeInfo.totalDistance}
            </Text>
            <Text>
              <span style={{ fontWeight: 'bold' }}>
                {t('route.table.ETC') + ': '}
              </span>
              {t('common.minutes', { min: routeInfo.ETC })}
            </Text>
            <EditRouteDetailModal routeInfo={routeInfo} />
          </Space>
          <Space style={styles.otherInfo} align="start">
            <Card
              size="small"
              title={t('route.routeDetail.header.driverInfo.title')}
              extra={
                <Button size="small" onClick={() => setShowDriverModal(true)}>
                  {t('route.routeDetail.header.driverInfo.assignDriver')}
                </Button>
              }
              style={{ width: 300 }}
            >
              {routeInfo.driver ? (
                <Space direction="vertical">
                  <Text>
                    <span style={{ fontWeight: 'bold' }}>
                      {t('driver.table.name') + ': '}
                    </span>
                    {routeInfo.driver.driver_name}
                  </Text>
                  <Text>
                    <span style={{ fontWeight: 'bold' }}>
                      {t('driver.table.phone') + ': '}
                    </span>
                    {routeInfo.driver.phone}
                  </Text>
                  <Text>
                    <span style={{ fontWeight: 'bold' }}>
                      {t('driver.table.status') + ': '}
                    </span>
                    <DriverStatus status={routeInfo.driver.status} />
                  </Text>
                  <Text>
                    <span style={{ fontWeight: 'bold' }}>
                      {t('driver.table.activeCode') + ': '}
                    </span>
                    <Tag>{routeInfo.driver.active_code}</Tag>
                  </Text>
                  <Text>
                    <span style={{ fontWeight: 'bold' }}>
                      {t('driver.table.locationUpdateTime') + ': '}
                    </span>
                    {routeInfo.driver.location_update_at
                      ? dayjs(routeInfo.driver.location_update_at).format(
                          'YYYY/MM/DD HH:mm'
                        )
                      : 'N/A'}
                  </Text>
                </Space>
              ) : (
                <Text>
                  {t('route.routeDetail.header.driverInfo.notAssignedYet')}
                </Text>
              )}
            </Card>
            {showDriverModal ? (
              <AssignDriverModal
                setShowDriverModal={setShowDriverModal}
                routeId={routeInfo.id}
              />
            ) : (
              <></>
            )}
            <Card
              title={t('route.routeDetail.header.otherInfo.title')}
              size="small"
            >
              <Space direction="vertical">
                <Button block onClick={() => setShowRouteLogModal(true)}>
                  {t('route.routeDetail.header.otherInfo.routeLog')}
                </Button>
                <DeleteRouteBtn
                  routeId={routeInfo.id}
                  shippingDate={routeInfo.shipping_date}
                />
              </Space>
            </Card>
            {showRouteLogModal ? (
              <RouteLogModal
                showRouteLogModal={showRouteLogModal}
                setShowRouteLogModal={setShowRouteLogModal}
                routeId={routeInfo.id}
              />
            ) : (
              <></>
            )}
          </Space>
        </div>
      </Spin>
    </div>
  );
};

const styles: StyleSheet = {
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  basicInfo: {
    flex: 1,
  },
  otherInfo: {
    flex: 1,
  },
};

export default HeaderSection;
