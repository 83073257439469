import { useLazyQuery } from '@apollo/client';
import { Input, message, Modal, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { SIMPLE_DRIVER_LIST } from '../../../GraphQL/queries/statistics';
import { graphqlErrorMessageAlert } from '../../../utils/tools';
import { SimpleDriverInfo } from '../../../views/Dashboard/Statistics';
import Loading from '../../common/Loading';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Column } = Table;

type DriversPickerProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedDrivers: React.Dispatch<React.SetStateAction<SimpleDriverInfo[]>>;
};

const DriversPicker = ({
  visible,
  setVisible,
  setSelectedDrivers,
}: DriversPickerProps) => {
  const { t } = useTranslation();

  //Driver list get from backend
  const [allDrivers, setAllDrivers] = useState<any[]>([]);
  //Filtered list if user type something in search input
  const [displayedDrivers, setDisplayedDrivers] = useState<any[]>([]);

  //Driver being selected in modal (before click OK button)
  const [drivers, setDrivers] = useState<SimpleDriverInfo[]>([]);
  const [searchText, setSearchText] = useState('');

  const [loadDrivers, { loading, data }] = useLazyQuery(SIMPLE_DRIVER_LIST, {
    onError: (err) => graphqlErrorMessageAlert(err),
    onCompleted: (data) => {
      if (data.drivers) {
        setAllDrivers(data.drivers);
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    //Model show up, load driver list
    if (visible) {
      setSearchText('');
      setDrivers([]);
      loadDrivers();
    }
  }, [visible, loadDrivers]);

  useEffect(() => {
    if (allDrivers.length > 0) {
      const filteredDrivers = allDrivers.filter((driver) =>
        driver.driver_name.toLowerCase().includes(searchText.toLowerCase())
      );
      setDisplayedDrivers(filteredDrivers);
    }
  }, [searchText, allDrivers]);

  const handleOk = () => {
    if (drivers.length > 0) {
      setSelectedDrivers(drivers);
      setVisible(false);
    } else {
      message.warning(t('statistics.pickAtLeastOne'));
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const renderList = () => {
    if (loading) return <Loading />;

    if (data && data.drivers) {
      const onSelectChange = (selectedRowKeys: any) => {
        const targetDrivers = selectedRowKeys.map((driverId: string) => {
          const targetDriver = data.drivers.find(
            (item: { driver_id: string; driver_name: string }) => {
              return item.driver_id === driverId;
            }
          );

          return {
            driverId: driverId,
            name: targetDriver.driver_name,
          };
        });

        setDrivers(targetDrivers);
      };

      const rowSelection = {
        onChange: onSelectChange,
      };

      const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
      };

      return (
        <Space direction="vertical" style={{ width: '100%' }}>
          <Input
            onChange={onSearch}
            prefix={<SearchOutlined />}
            value={searchText}
            placeholder={t('driver.searchPlaceholder')}
          />
          <Table
            sticky
            dataSource={displayedDrivers}
            rowSelection={rowSelection}
            pagination={{ defaultPageSize: 10 }}
            rowKey={(record: any) => record.driver_id}
          >
            <Column title="ID" dataIndex="driver_id" key="driver_id" />
            <Column title="Name" dataIndex="driver_name" key={Math.random()} />
          </Table>
        </Space>
      );
    }

    return <></>;
  };

  return (
    <Modal
      title={t('statistics.selectDrivers')}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t('common.confirm')}
      cancelText={t('common.cancel')}
      maskClosable={false}
      destroyOnClose={true}
    >
      {renderList()}
    </Modal>
  );
};

export default DriversPicker;
