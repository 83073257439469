import { DashboardRoute, RootState, UserState } from '../../types';
import React from 'react';
import {
  UserOutlined,
  HomeOutlined,
  ShoppingOutlined,
  TeamOutlined,
  CarOutlined,
  NodeIndexOutlined,
  BookOutlined,
  SettingOutlined,
  ShopOutlined,
  BarChartOutlined,
} from '@ant-design/icons';
import Home from '../../views/Dashboard/Home';
import Orders from '../../views/Dashboard/Orders';
import Users from '../../views/Dashboard/Users';
import Profile from '../../views/Dashboard/Profile';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Drivers from '../../views/Dashboard/Drivers';
import Route from '../../views/Dashboard/Route/Route';
import AddressBook from '../../views/Dashboard/AddressBook/AddressBook';
import Preference from '../../views/Dashboard/Settings/Preference';
import Statistics from '../../views/Dashboard/Statistics';
import DefaultAddressBook from '../../views/Dashboard/AddressBook/DefaultAddressBook';
import Stores from '../../views/Dashboard/Stores';

const DashboardRouter = ({
  children,
}: {
  children: (routes: any) => JSX.Element;
}) => {
  const { t } = useTranslation();
  const userInfo = useSelector<RootState, UserState['userInfo']>(
    (state) => state.user.userInfo
  );

  const routes: DashboardRoute[] = [
    {
      path: '/home',
      component: Home,
      title: t('dashboardRoutes.home'),
      icon: <HomeOutlined />,
      roles: [],
    },
    {
      path: '/routes',
      component: Route,
      title: t('dashboardRoutes.routes'),
      icon: <NodeIndexOutlined />,
      roles: [],
    },
    {
      path: '/orders',
      component: Orders,
      title: t('dashboardRoutes.orders'),
      icon: <ShoppingOutlined />,
      roles: [],
    },
    {
      path: '/drivers',
      component: Drivers,
      title: t('dashboardRoutes.drivers'),
      icon: <CarOutlined />,
      roles: [],
    },
    {
      path: '/stores',
      component: Stores,
      title: t('dashboardRoutes.stores'),
      icon: <ShopOutlined />,
      roles: [],
    },
    {
      path: '/statistics',
      component: Statistics,
      title: t('dashboardRoutes.statistics'),
      icon: <BarChartOutlined />,
      roles: [],
    },
    {
      path: '/address',
      title: t('addressBook.address'),
      icon: <BookOutlined />,
      roles: [],
      subMenu: [
        {
          title: t('dashboardRoutes.addressBook'),
          path: '/address-book',
          component: AddressBook,
        },
        {
          title: t('addressBook.defaultAddress.title'),
          path: '/default-address',
          component: DefaultAddressBook,
        },
      ],
    },
    {
      path: '/users',
      component: Users,
      title: t('dashboardRoutes.users'),
      icon: <TeamOutlined />,
      roles: [],
    },
    {
      path: '/profile',
      component: Profile,
      title: t('dashboardRoutes.profile'),
      icon: <UserOutlined />,
      roles: [],
    },
    {
      path: '/settings',
      title: t('dashboardRoutes.settings'),
      icon: <SettingOutlined />,
      roles: [],
      subMenu: [
        {
          title: t('settings.preference.title'),
          path: '/preference',
          component: Preference,
        },
      ],
    },
  ];

  const availableRoutes = routes.filter((route) => {
    if (route.roles.length === 0) return true;
    const authorizedRoutes = route.roles.find((role) => role === userInfo.role);
    if (authorizedRoutes) return true;
    return false;
  });

  return children(availableRoutes);
};

export default DashboardRouter;
