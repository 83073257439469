import { useLazyQuery } from '@apollo/client';
import { Button, Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DRIVER_STATISTICS_DETAIL } from '../../../GraphQL/queries/statistics';
import {
  graphqlErrorMessageAlert,
  orderStatusToString,
} from '../../../utils/tools';
import Loading from '../../common/Loading';
import ExportCSV from './ExportCSV';
import { useSelector } from 'react-redux';
import { RootState, StatisticsState } from '../../../types';

const { Column } = Table;

type DateDetailModalProps = {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  pickedDriver: { id: number; name: string };
  shippingDate: { start: string; end: string };
};

const DateDetailModal = ({
  isModalVisible,
  setIsModalVisible,
  pickedDriver,
  shippingDate,
}: DateDetailModalProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const storeId = useSelector<RootState, StatisticsState['storeId']>(
    (state) => state.statistics.storeId
  );

  const [getDetail, { called, loading, data }] = useLazyQuery(
    DRIVER_STATISTICS_DETAIL,
    {
      onError: graphqlErrorMessageAlert,
      variables: {
        input: {
          driver_id: pickedDriver.id,
          start_date: shippingDate.start,
          end_date: shippingDate.end,
          store_id: storeId,
        },
      },
    }
  );

  //Fetch Report when user click different driver
  useEffect(() => {
    if (pickedDriver.id > 0) {
      getDetail();
    }
  }, [pickedDriver, getDetail]);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleJumpToOrderByDate = (
    driverId: number | string,
    status: number,
    date: string
  ) => {
    history.push('orders', {
      searchConditions: {
        driver_id: driverId,
        status: status,
        shipping_date: date,
      },
    });
  };

  const renderResult = () => {
    if (called && loading) return <Loading />;

    if (data) {
      const dataArray: [
        string,
        {
          totalTips: number;
          totalDistance: number;
          validOrderCount: number;
          totalOrderStatus: number[];
        }
      ][] = Object.entries(data.driverDataDetail.orderDetailAnalysis);

      const assembleData = dataArray.map(([key, value]) => {
        return {
          date: key,
          ...value,
        };
      });

      return (
        <>
          <div style={{ marginBottom: 10 }}>
            <ExportCSV
              data={
                assembleData.length > 0
                  ? assembleData.map((data) => {
                      return {
                        [`${t('route.table.date')}`]: data.date,
                        [`${t('statistics.table.totalDistance')}`]:
                          data.totalDistance,
                        [`${t('statistics.table.valid')}`]:
                          data.validOrderCount,
                        Tips: `$${data.totalTips.toFixed(2)}`,
                        [`${orderStatusToString(4)}`]: data.totalOrderStatus[4],
                        [`${orderStatusToString(3)}`]: data.totalOrderStatus[3],
                        [`${orderStatusToString(2)}`]: data.totalOrderStatus[2],
                        [`${orderStatusToString(1)}`]: data.totalOrderStatus[1],
                        [`${orderStatusToString(0)}`]: data.totalOrderStatus[0],
                      };
                    })
                  : []
              }
              filename={`${pickedDriver.name} (${shippingDate.start} - ${shippingDate.end}).csv`}
            />
          </div>
          <Table
            dataSource={assembleData}
            sticky
            pagination={{ defaultPageSize: 100 }}
            rowKey={(record: any) => record.date}
          >
            <Column title={t('route.table.date')} dataIndex="date" />
            <Column
              title={t('statistics.table.valid')}
              dataIndex="validOrderCount"
              className="highlightColumn1"
            />
            <Column
              title={t('statistics.table.totalDistance')}
              dataIndex="totalDistance"
              className="highlightColumn1"
            />
            <Column
              title="Tips"
              dataIndex="totalTips"
              key={Math.random()}
              className="highlightColumn2"
              render={(value: any) => `$ ${value.toFixed(2)}`}
            />
            {[4, 3, 2, 1, 0].map((i) => {
              return (
                <Column
                  title={orderStatusToString(i)}
                  dataIndex="totalOrderStatus"
                  key={i}
                  render={(value: number[], record: any) => (
                    <Button
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleJumpToOrderByDate(
                          pickedDriver.id,
                          i,
                          record.date
                        );
                      }}
                      disabled={value[i] === 0}
                      type="text"
                    >
                      {value[i]}
                    </Button>
                  )}
                />
              );
            })}
          </Table>
        </>
      );
    }

    return <></>;
  };

  return (
    <>
      <Modal
        title={`${pickedDriver.name} (${shippingDate.start} - ${shippingDate.end})`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'80vw'}
        footer={<></>}
      >
        {renderResult()}
      </Modal>
    </>
  );
};

export default DateDetailModal;
