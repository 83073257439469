import { gql } from '@apollo/client';

export const SIMPLE_DRIVER_LIST = gql`
  query DriverList {
    drivers {
      driver_id
      driver_name
    }
  }
`;

export const GET_DRIVER_STATISTICS = gql`
  query DriverStatistics($input: driverDataInput) {
    driverData(input: $input) {
      driver {
        driver_id
        driver_name
      }
      totalOrders
      validOrderCount
      totalOrderStatus
      totalTips
      totalDistance
    }
  }
`;

export const DRIVER_STATISTICS_DETAIL = gql`
  query DriverDataDetail($input: driverDataDetailInput) {
    driverDataDetail(input: $input) {
      driver {
        driver_id
        driver_name
      }
      orderDetailAnalysis
    }
  }
`;
