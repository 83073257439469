import { gql } from '@apollo/client';

export const GET_ROUTES = gql`
  query Routes($date: String) {
    routes(date: $date) {
      id
      name
      short_name
      shipping_date
      start_at
      driver {
        driver_id
        driver_name
      }
      ETC
      orderCount
      totalDistance
    }
  }
`;

export const GET_ROUTE = gql`
  query Route($id: ID!) {
    route(id: $id) {
      id
      name
      short_name
      shipping_date
      driver {
        driver_id
        driver_name
        phone
        status
        active_code
        latitude
        longitude
        location_update_at
      }
      start_at
      orderCount
      orders {
        id
        address
        phone
        receiver
        email
        order_sn
        latitude
        longitude
        remark
        status
        type
        sequence_index
        sequence_label
        created_at
        update_at
        arrival
        distance
        unit
        buzz
        building_type
        driver_remark
        order_images {
          id
          img_url
        }
        store {
          id
          store_name
        }
      }
      startAddress {
        id
        name
        label
        latitude
        longitude
      }
      endAddress {
        id
        name
        label
        latitude
        longitude
      }
      geoJson
      ETC
      totalDistance
    }
  }
`;

export const ADD_ROUTE = gql`
  mutation AddRoute(
    $name: String!
    $short_name: String!
    $shipping_date: DateTime!
    $start_point_id: Int!
    $end_point_id: Int!
  ) {
    addRoute(
      name: $name
      short_name: $short_name
      shipping_date: $shipping_date
      start_point_id: $start_point_id
      end_point_id: $end_point_id
    ) {
      id
      name
      shipping_date
      update_at
      created_at
    }
  }
`;

export const UPDATE_ROUTE_INFO = gql`
  mutation UpdateRoute($id: ID!, $input: updateRouteInput!) {
    updateRoute(id: $id, input: $input) {
      id
      name
      short_name
      shipping_date
      driver {
        driver_id
        driver_name
        phone
        status
        active_code
      }
      orders {
        id
        address
        phone
        receiver
        email
        order_sn
        latitude
        longitude
        remark
        status
        type
        created_at
        update_at
      }
      startAddress {
        name
        label
        latitude
        longitude
      }
      endAddress {
        name
        label
        latitude
        longitude
      }
    }
  }
`;

export const CALCULATE_ROUTE = gql`
  mutation CalculateRoute($id: ID!, $type: Int) {
    optimizeRoute(id: $id, type: $type) {
      id
      address
      phone
      receiver
      email
      order_sn
      latitude
      longitude
      remark
      status
      type
      created_at
      update_at
    }
  }
`;

export const GET_ROUTE_LOG = gql`
  query RouteLog($id: ID!) {
    routeLogs(id: $id) {
      id
      route_id
      remark
      created_at
      user {
        name
      }
    }
  }
`;

export const GET_DEFAULT_ROUTES = gql`
  query {
    defaultRoute {
      routeInfo
    }
  }
`;

export const DELETE_ROUTE = gql`
  mutation DeleteRoute($id: ID!) {
    deleteRoute(id: $id)
  }
`;

export const ROUTE_COMPARE = gql`
  query Routes($date: String!) {
    routes(date: $date, includeGeoJson: true) {
      id
      name
      short_name
      geoJson
      orders {
        id
        address
        phone
        receiver
        email
        order_sn
        latitude
        longitude
        remark
        status
        type
        sequence_index
        sequence_label
        created_at
        update_at
        arrival
        distance
        unit
        buzz
        building_type
        driver_remark
        order_images {
          id
          img_url
        }
      }
    }
  }
`;

export const REVERSE_ROUTE = gql`
  mutation reverse($id: ID!) {
    reverseRoute(id: $id) {
      status
    }
  }
`;

export const SPLIT_ROUTE = gql`
  mutation SplitRoute($id: ID!, $input: [splitRouteInput]!) {
    splitRoute(id: $id, input: $input) {
      id
      route_id
      address
      building_type
      unit
      buzz
      phone
      receiver
      email
      order_sn
      latitude
      longitude
      remark
      driver_remark
      status
      type
      sequence_index
      arrival
      distance
      partner_order_detail
      created_at
      update_at
      delivery_at
      complete_at
    }
  }
`;
